
// Autogenerated
// -------------
// gulpfile.ts/wasm.ts --> generateBuildInfo

export const Build = {
    version: "0.71.0 (d4bfb2efc0046f1c59c6bf222cd30533)",
    buildSeed: 1630491930431,
    "wdosbox.wasm": {
        "size": 1461250,
        "gzSize": 491232
    },
    "wdosbox.js": {
        "size": 107589,
        "gzSize": 27656
    },
    "wlibzip.wasm": {
        "size": 112300,
        "gzSize": 54090
    },
    "wlibzip.js": {
        "size": 80251,
        "gzSize": 20720
    }
,
};
